import React from 'react';
import { graphql } from 'gatsby';

import { PageHelmet } from '../components/PageHelmet';
import ImageTextSideBySide from '../components/SectionBlocks/RepeatableSections/ImageTextSideBySide';
import { pageUrls } from '../config/pageSetting';
import Content, { HTMLContent } from '../components/Atoms/Content';

// ********************************* UI Template (Shared with CMS) *********************************
export const StoryPageTemplate = ({
  content, contentComponent, title, description, bgImg, cms = false,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div>
      <PageHelmet title={title} description={description} />
      <ImageTextSideBySide
        img={bgImg}
        title={pageUrls.ourStory.title}
        goldAtTop
        textAlign={['center', 'center', 'center', 'left']}
      >
        <PostContent content={content} />
      </ImageTextSideBySide>
    </div>
  );
};

// ********************************* Render page *********************************
const StoryPage = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark;

  return (
    <StoryPageTemplate
      content={html}
      contentComponent={HTMLContent}
      {...frontmatter}
    />
  );
};

export default StoryPage;

// ********************************* Data graphql *********************************
export const pageQuery = graphql`
  query StoryPageWithId($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      htmlAst
      frontmatter {
        title
        description
        bgImg {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
